/* Proof of concept -- undo later */
@media all and (max-width: 500px) {
  .respTable {
    display: block;
  }
  .respTableCell {
    display: block;
    width: 100% !important;
  }
}
